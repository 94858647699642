<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <el-select class="me-2 w-250px" size="large" v-model="searchParams.productId" filterable :placeholder="generalConstants.SELECTPRODUCT">
            <el-option :label="pdata.product_name" :value="pdata.product_id" :key="pdata"  v-for="pdata in productData"></el-option>
          </el-select>
          <el-select class="me-2 w-250px" v-model="searchParams.partner" :placeholder="generalConstants.SELECTTRADINGPARTNER" size="large">
            <el-option value="">{{generalConstants.SELECTTRADINGPARTNER}}</el-option>
            <el-option v-for="partner in tradingPartners" :value="partner.trading_partner_id" :key="partner" :label="partner.user.name">
              {{partner.user.name}}
            </el-option>
          </el-select>
          <el-date-picker
            class="me-2"
            v-model="searchParams.searchByOrderDate"
            type="daterange"
            range-separator="To"
            value-format="YYYY-MM-DD"
            start-placeholder="Order date"
            end-placeholder="Order date"
            size="large"
          />
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
            <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
            <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
          </el-tooltip>
        </div>
      </div>
      <div class="card-toolbar">
        <div class="col-md-auto me-0 ms-auto me-2">
          <a
            href="javascript:void(0);"
            class="btn btn-warning"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
            ><i class="bi bi-file-earmark-arrow-down fs-4"></i> {{ constants.report.EXPORT_PRODUCT_SALES }}
            <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
            </span>
          </a>
          <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-purple fw-bold fs-7 w-250px py-4" data-kt-menu="true">
            <div class="menu-item px-3">
              <a @click="exportProductSalesSummeryData('xlsx')" class="menu-link px-3"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ constants.report.PRODUCT_SALES_EXCEL_EXPORT }}</a>
            </div>
            <div class="menu-item px-3">
              <a @click="exportProductSalesSummeryData('csv')" class="menu-link px-3"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ constants.report.PRODUCT_SALES_CSV_EXPORT }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-6">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-product_name="{ row: data }">
          {{ data.name }}
        </template>
        <template v-slot:cell-order_count="{ row: data }">
          {{ data.order_count }}
        </template>
        <template v-slot:cell-quantity="{ row: data }">
          {{ data.quantity > 0 ? data.quantity : 0 }}
        </template>
        <template v-slot:cell-avg_quantity="{ row: data }">
            <template v-if="data.quantity > 0">
            {{ Math.round(data.quantity/data.order_count) }}
            </template>
            <template v-else>
                {{ 0 }}
            </template>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatPrice, formatDate, formatText } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { notificationFire } from "@/composable/notification.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default {
  name: "product-sales-report",
  components: {
    Datatable
  },
  setup() {
    let total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const constants = globalConstant;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const productData = reactive([]);
    const tradingPartners = ref([]);
    const searchParams = reactive({
      recordsPerPage: Number(JSON.parse(window.localStorage.getItem('site_config'))['per_page']),
      pageNub: 1,
      partner:"",
      search:"",
      sortBy:"",
      sortDesc:"",
      productId: "",
      searchByOrderDate: "",
      type:"listing"
    })

    const permission = reactive({
      isViewProductSalesReportAllowed: false,
      isExportSalesReportData: false
    })

    const tableHeader = reactive([
      {
        name: 'Product Name',
        key: 'product_name',
        width: "w-200px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Total Order Count',
        key: "order_count",
        sortable: true,
        width: "w-10px",
        visible: true,
        alignment: 'text-start'
      },
      {
        name: 'Quantity',
        key: 'quantity',
        width: "w-100px",
        visible: true,
        alignment: 'text-center'
      },
      {
        name: 'Avg. Quantity( Quantity / Orders )',
        key: 'avg_quantity',
        width: "w-100px",
        visible: true,
        alignment: 'text-center'
      },
    ]);
    
    const handleSizeChange = (number) => {
      searchParams.recordsPerPage = number;
      handleCurrentChange(1);
    }

    const handleSortChange = (obj) => {
      searchParams.sortBy = obj.columnName;
      searchParams.sortDesc = obj.order;
      getProductSales();
    }
    
    const handleCurrentChange = (number) => {
      searchParams.pageNub = number;
      getProductSales();
    }

    //Fetch the all the products
    const getProducts = () => {
      ApiService.query('products')
        .then(({ data }) => {
          if (data) {
            data.data.forEach((obj) => {
              productData.push({
                product_id: obj.product_id,
                product_name: obj.name,
              });
            });
          }
        })
        .catch((error) => {
          let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404 || status == 422) {
                router.push({ name: 'product-SKUs'})
              }
            }
        });
    }

    //Get all active trading partners
    const getTradingPartners = async () => {
      loading.value = true;
      await ApiService.query("get-active-trading-partner")
      .then(({ data }) => {
        if (data.data) {
          tradingPartners.value = data.data;
        } else {
          tradingPartners.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tradingPartners.value = [];
        loading.value = false;
      });
    }

    //Get all product sales data
    const getProductSales = async () => {
      loading.value = true;
      searchParams.type = "listing";
      await ApiService.post("reports/product-sales-summery", { params: searchParams })
      .then(({ data }) => {
        if (data.data.data.length) {
          tableData.value.splice(0, tableData.value.length, ... data.data.data);
          total.value = data.data.total;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
        }
        else
        {
          tableData.value = [];
          total.value = 0;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
        setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tableData.value = [];
        total.value = 0;
        loading.value = false;
      });
    }

    //Reset search value
    const resetSearch = () => {
      searchParams.productId = "";
      searchParams.searchByOrderDate = "";
      searchParams.partner = "";
      getProductSales();
    };

    //Order search event function
    const handleSearch = () => {
      handleCurrentChange(1);
    }

    //Export order summery data
    const exportProductSalesSummeryData = (extension) => {
      searchParams.type = "download";
      axios({
        url: 'reports/product-sales-summery',
        method: 'POST',
        data: {
          params: searchParams,
          extension: extension
        },
        responseType: 'arraybuffer',
      }).then((response) => {
        let blob = new Blob([response.data], {
            type: ''
        })
        let link = document.createElement('a')
        link.setAttribute('target','_blank')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'product_sales_'+Date.now()+'.'+extension
        link.click()
      })
      .catch((error) => {
        let message = error.message;
        if (message != "") {
          notificationFire(message, "error");
        }
      });
      return false;
    }

    //Set breadcrumbs and get the product sales data for report
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("ViewProductSalesReport") || role == "Super Admin")
        permission.isViewProductSalesReportAllowed = true;
      if (per.includes("ExportProductSalesReportData") || role == "Super Admin")
        permission.isExportSalesReportData = true;
      await getProductSales();
      getProducts();
      getTradingPartners();
      MenuComponent.reinitialization();
    })
    
    return {
      tableData,
      formatPrice,
      formatDate,
      getProductSales,
      total,
      handleSizeChange,
      handleCurrentChange,
      handleSortChange,
      loading,
      searchParams,
      tableHeader,
      generalConstants,
      noDataFound,
      updateProps,
      resetSearch,
      ordersConstants,
      handleSearch,
      permission,
      exportProductSalesSummeryData,
      productData,
      constants,
      tradingPartners
    }
  },
};

</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.el-link {
  justify-content: left;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
// For Export Button
.btn.btn-gray{
  background-color: #858585;
  border-color: #858585;
  color:#ffffff;
}
.btn.btn-gray i{color:#ffffff;}
.btn.btn-gray svg path{fill:#ffffff !important;}
.btn.btn-gray:hover,.btn.btn-gray:focus,.btn.btn-gray:active{
  background-color: #666666;
  border-color: #666666;
}
.menu-state-bg-light-gray .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-gray .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f3f3f3;
  color: #858585;
}
</style>